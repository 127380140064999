<template>
  <v-form v-model="valid" :disabled="loading" @submit.prevent="onSubmit">
    <v-card-text>
      <v-text-field
        v-model="organizationData.description"
        class="mb-2"
        :label="$t('admin.organizations.field.description') + ' *'"
        :rules="[v => !!v || $t('validation.required')]"
      />

      <v-select
        v-model="organizationData.type"
        :label="$t('admin.organizations.field.type') + ' *'"
        :items="[OrganizationType.CUSTOMER, OrganizationType.SUPPLIER, OrganizationType.LABEL]"
        :rules="[v => !!v || $t('validation.required')]"
      >
        <template #selection="{ item }">
          <organization-type-badge :type="item.value" />
        </template>
        <template #item="{ item, props: itemProps }">
          <v-list-item v-bind="itemProps" :title="undefined">
            <organization-type-badge :type="item.value" />
          </v-list-item>
        </template>
      </v-select>

      <v-select
        v-model="organizationData.domain"
        :disabled="!!organization"
        :label="$t('admin.domains.title') + ' *'"
        :items="[DomainType.CIVIL, DomainType.PUMPING_STATIONS]"
        :rules="[v => !!v || $t('validation.required')]"
      >
        <template #selection="{ item }">
          <domain-type-badge :type="item.value" />
        </template>
        <template #item="{ item, props: itemProps }">
          <v-list-item v-bind="itemProps" :title="undefined">
            <domain-type-badge :type="item.value" />
          </v-list-item>
        </template>
      </v-select>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
      <v-btn color="primary" type="submit" :loading="loading">{{ organization ? $t("save") : $t("add") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
import { OrganizationType, DomainType } from "~~/stores/organization.store"

type OrganizationData = Pick<Organization, "description" | "type" | "domain">

const emit = defineEmits<{
  (e: "submit", organizationData: Organization): void
  (e: "cancel"): void
}>()

const props = defineProps<{
  organization?: Organization
  organizationType?: OrganizationType
}>()
const { organizationType, organization } = toRefs(props)

const $i18n = useI18n()
const mainStore = useMainStore()
const organizationStore = useOrganizationStore()

const loading = ref(false)
const valid = ref<boolean | null>()

const organizationData = ref<OrganizationData>({
  description: organization.value?.description ?? "",
  type: organization.value?.type ?? organizationType?.value ?? OrganizationType.CUSTOMER,
  domain: organization.value?.domain ?? DomainType.PUMPING_STATIONS
})

const onSubmit = async () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    loading.value = true

    if (organization.value) {
      const updatedOrganization = await organizationStore.updateOrganization(organization.value._id, organizationData.value)
        .finally(() => { loading.value = false })

      emit("submit", updatedOrganization)
    } else {
      const newOrganization = await organizationStore.createOrganization(organizationData.value)
        .finally(() => { loading.value = false })

      emit("submit", newOrganization)
    }
  })
}
</script>